<template>
    <section id="mechanicalhead" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h3>Ever expanding from aerospace to Chemical and moving on to nano technology</h3>
          <p style="padding-top:20px">Our engineers work with passion and dedication and approach problems with analytical reasoning.</p>
          <p >We work with applying software in the machine world. Each upgradation in the machines we use is focused on problem-solving features, in the machines in common use as the phones, tablets to the automobiles. Innovation and constant development are the principles we follow in our company.</p>
        </div>

        <div class="row" style="padding-top:30px">
          <div class="col-lg-3 col-md-6 col-12 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
            <Image :fpath=" obj.path " class="serviceimage"/>
              <h4 class="mechhead">{{ obj.header }}</h4>
              <h6 class="mechpar">{{obj.subtitile}}</h6>
                <p>{{ obj.caption }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'services/COMPUTER-DESIGNING.jpg',
        header: 'COMPUTER AIDED DESIGNING',
        subtitile: 'Outline your dream Product',
        caption: 'Computer technology aids modeling a 3D or 2D design. It documents each change that we make. With the help of this technology, we can choose our own conceptual design, choose a layout and can manipulate a view.'
      },
      {
        path: 'services/PRODUCT-MANAGEMENT.jpg',
        header: 'PRODUCT LIFE-CYCLE MANAGEMENT',
        subtitile: 'Manage a Product’s life-stages',
        caption: 'Every manufactured product goes through a life- cycle during which the challenges that offer growth and decline in a product, is maintained by various strategies of Product life – cycle management.'
      },
      {
        path: 'services/AUTOMOBILE-ENGINEERING.jpg',
        header: 'AUTOMOBILE ENGINEERING',
        subtitile: 'Ever- Growing Automobile Industry',
        caption: 'With the ongoing changes made to the automobiles that are used every day, it has become a quite popular field of engineering. Developing, manufacturing and constant maintenance are various levels that ensures a perfect design and results in a complete functional end product.'
      },
      {
        path: 'services/AEROSPACE-ENGINEERING.jpg',
        header: 'AEROSPACE ENGINEERING',
        subtitile: 'Aircraft manufacturing',
        caption: 'This Engineering deals with developing aircraft, missile and weapon system. It requires hard work and dedication to work in this field since it is only based on interest and deep knowledge of the subject.'
      }
    ]

  })
    
}
</script>
<style>
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#mechanicalhead {
    padding: 50px 30px 10px 30px;
}
.services .icon-box i {
  float: left;
  color: #f6b024;
  font-size: 40px;
  line-height: 0;
}

.services .icon-box .mechhead {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 25px;
}
.mechpar{
  font-size: 14px;
}
.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>