<template>
    <Header/>
    <MechanicalEngineeringheader/>
    <MechanicalEngineeringSection/>
    <Mechanicalicons/>
    <Footer/>
</template>

<script>
// @ is an alias to /src

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import MechanicalEngineeringSection from '@/components/MechanicalEngineeringSection.vue'
import MechanicalEngineeringheader from '@/components/MechanicalEngineeringheader.vue'
import Mechanicalicons from '@/components/Mechanicalicons.vue'


export default {
  components: {
     Header,
    Footer,
    MechanicalEngineeringSection,
    MechanicalEngineeringheader,
    Mechanicalicons
  }
}
</script>