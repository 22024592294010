<template>
    <section id="Mechicon" class="services">
      <div class="container" >

        <div class="section-title">
          <h4>We provide services to all branches of mechanical engineering</h4>
          <p>Our mechanical professionals are dedicated towards their goals to achieve by using problem-solving techniques in the field of mechanics.</p>
       </div>

        <div class="row" style="padding-top:30px">
          <div class="col-lg-4 col-md-6 col-12 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
              <Image :fpath=" obj.path " class="embedimage"/>
              <h4 class="embservice">{{ obj.header }}</h4>
              <p class="embservice">{{ obj.caption }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
         path: 'background/Designing.png',
        header: 'Designing',
        caption: 'The most important part of mechanical engineering is the designing of its product. It is only with a solid foundation that a product can be built from its roots.'
      },
      {
         path: 'background/Developing.png',
        header: 'Developing',
        caption: 'An Engineer perfects his raw design by taking into consideration all the factors that may either directly or indirectly affect the product in question. The engineer’s passion and dedication drives the end product to its perfection.'
      },
      {
         path: 'background/Manufacturing.png',
        header: 'Manufacturing',
        caption: 'When the product outlined has been designed and tested to its completeness, the final stage is the manufacturing process. In this stage, there can be no drawbacks and so the product undergoes various processes before it is manufactured.'
      }
    ]

  })
    
}
</script>
<style>
.services .icon-box img {
    float: left;
    margin-top: 10px;
  }
img.embedimage {
    width: 15%;
}
 
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#Mechicon {
    padding: 50px 30px 10px 30px;
}
.services .icon-box i {
  float: left;
  color: #f6b024;
  font-size: 40px;
  line-height: 0;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 25px;
    margin-top: 10px;
}
.embservice{
  margin-left: 70px;
}
.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>